@import 'franklin-sites/src/styles/common/z-index';

@keyframes slide-in-side {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
}

.popup-message-container {
  opacity: 1;
  transform: translateX(0);

  // animation on mount
  animation-duration: 1s;
  animation-name: slide-in-side;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;

  z-index: $z-index-high;
  position: fixed;
  display: flex;
  width: 25vw;
  max-height: calc(100vh - 2rem);
  overflow: auto;
  flex-direction: column;
  right: 1rem;
  bottom: 1rem;
}
