@import 'franklin-sites/src/styles/mixins';

.main-content {
  margin: 0;

  & > li {
    display: inline-block;
    margin-inline: 0.25ch;
  }

  :global(.dropdown) {
    /* "Visually" aligned with "SPARQL" */
    /* TODO: find a better way to align this */
    transform: translateY(-2px);
  }

  .no-small {
    display: none;

    @include fs-breakpoints('medium') {
      display: inline-block;
    }
  }
}

.logo {
  background: var(--fr--color-sky-white);
  -webkit-mask: url('../../../../images/uniprot-logo.img.svg') no-repeat 0 0;
  mask: url('../../../../images/uniprot-logo.img.svg') no-repeat 0 0;
  width: 80px;
  height: 40px;

  &.home {
    background: var(--fr--color-sea-blue);
  }

  @include fs-breakpoints('large') {
    width: 100px;
    height: 54px;
  }
}
