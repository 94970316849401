@import 'franklin-sites/src/styles/mixins';

.stale,
.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

.break-anywhere {
  line-break: anywhere;
}

.bold {
  font-weight: bold;
}

.bolder {
  font-weight: bolder;
}

.emphasized {
  font-style: italic;
}

.no-text-transform {
  text-transform: none;
}

.no-small {
  &,
  &:global(.button) {
    display: none;

    @include fs-breakpoints('medium') {
      display: initial;
    }
  }
}

.overflow-y-container {
  max-width: 100%;
  overflow-y: auto;
}

.padding-top-small {
  padding-top: 0.5 * $global-padding;
}
