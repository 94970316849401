// Used to replace <p> when using EvidenceTag
.text-block {
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  display: block;
  margin-block-end: 1em;
  margin-inline: 0;

  .decorated-list-item & {
    margin-block-end: 0;
  }
}

// only show the first loader component when 2 or more are direct siblings
// useful for lists of potentially loading components
.loader-container + .loader-container {
  display: none;
}

.card__header {
  .tiny,
  .micro {
    // Add a bit more of padding to the top of smaller headers only
    padding-top: 0.5em;
  }
}

/* contextual help */
/* global style now to avoid layout shift when loading logic later */
[data-article-id] {
  &::after {
    content: 'i';
    cursor: wait;
    padding-inline-start: 0.1rem;
    font-size: 0.75rem;
    font-family: Bookman, Garamond, serif;
    position: relative;
    line-height: 1rem;
    vertical-align: super;
    opacity: 0.5; /* overriden whenever the logic loads */
    transition: opacity 250ms ease-in-out;
  }

  thead &::after {
    display: none;
  }
}

protvista-manager {
  &:hover,
  &:focus-within {
    & path.rectangle.feature {
      stroke: gray;
    }
  }
}

table {
  border-collapse: collapse;
}

// TODO: remove as part of TRM-26871
.protvista-uniprot-structure__structure protvista-structure {
  z-index: 10 !important;

  & .msp-viewport-controls {
    display: none;
  }
}

// Refactor as part of TRM-32372
.protvista-uniprot-structure__meta .theme-selection {
  white-space: nowrap;

  & .am-disabled label {
    cursor: not-allowed;
  }
}

.zoom-image-container img {
  width: 100%;
}

// margin in order to not have the feedback/help buttons cover the viewer
.hotjar-margin {
  margin-inline-end: 1.2rem;
}

@for $i from 1 through 6 {
  h#{$i}[data-article-id] {
    display: inline-block;
  }
}

// Debug utility to show heading levels
// @for $i from 1 through 6 {
//   h#{$i} {
//     border: #{7 - $i}px solid green;
//     position: relative;

//     &:after {
//       content: 'h#{$i}';
//       background: rgba(0, 0, 0, 0.5);
//       color: lightgreen;
//       position: absolute;
//       top: 0;
//       right: 0;
//     }
//   }
// }

:root {
  --table__even: #fff;
  --table__header-background: #fff;
  --table__header-text: #393b42;
  --table__odd: #e4e8eb;
  --table_border: #c2c4c4;
  --table_hover: #f1f1f1;
}

/* Make sure to not animate views if user has prefers-reduced-motion on */
@media (prefers-reduced-motion) {
  ::view-transition-group(*),
  ::view-transition-old(*),
  ::view-transition-new(*) {
    animation: none !important;
  }
}
