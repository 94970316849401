@import 'franklin-sites/src/styles/common/z-index';

$black: #000;

.gdpr-section {
  width: 100%;
  font-size: 0.85rem;
  padding: 1rem 2rem;
  position: fixed;
  bottom: 0;
  background-color: lighten($black, 0.2);
  color: #fff;
  z-index: $z-index-high + 3;

  a {
    color: #fff;
  }

  button {
    margin-left: 2rem;
    margin-bottom: 0;
  }
}
