@import 'franklin-sites/src/styles/common/z-index';
@import 'franklin-sites/src/styles/colours';
@import 'franklin-sites/src/styles/mixins';

.secondary-item {
  position: relative;

  svg {
    width: 2ch;
    height: 2ch;
  }
}

.bubble {
  position: absolute;
  left: 1ch;
  top: -1.25em;
  z-index: $z-index-low;
  transform: scale(0.75);

  --main-bubble-color: #{$colour-success};
}

.basket-panel :global(.sliding-panel__content) {
  padding: 0;
}

.link-in-panel-title {
  color: $colour-sky-white;

  &:hover,
  &:focus {
    color: $colour-sky-white;
    border-bottom: 1px solid currentColor;
  }
}
