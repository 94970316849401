.error-component {
  width: 100%;
  padding: 1ch;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 1;

  // animation on mount
  animation-delay: 500ms;
  animation-duration: 500ms;
  animation-name: appear;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

// keyframe to have the base style when the component mounts,
// the "to" is automatically decided with what we define in the component's code
@keyframes appear {
  from {
    opacity: 0;
  }
}
